import React from "react";
import "../styles/privacy.css"

function Privacy() {
  return (
    <div className="my-20">
      <div className="private block md:p-36 lg:p-36 p-10 mx-auto md:w-4/6 lg:w-4/6">
        <div className="block">
          <h4 className="text-white text-center">Policy</h4>
          <p className="text-white text-center sub-content">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Servh4ice and tells You about Your privacy rights and how the law
            protects You. We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy.
          </p>
        </div>
        <div className="my-12">
          <p className="lead">Interpretation and Definitions</p>
          <p className="lead">Interpretation</p>
           <p className="sub-content">The words of which the initial letter is capitalized
          have meanings defined under the following conditions. The following
          definitions shall have the same meaning regardless of whether they
          appear in singular or in plural.</p>
        </div>
        <div className="my-12">
          <p className="lead"> Definitions</p>
         <p className="sub-content"> For the purposes of this Privacy Policy:</p>
          <p className="sub-content">Account means a unique
          account created for You to access our Service or parts of our Service.</p>
          <p className="sub-content">
          Application refers to Qol Super App, the software program provided by
          the Company. 
          </p>
         <p className="sub-content"> Company (referred to as either "the Company", "We", "Us"
          or "Our" in this Agreement).</p>

           <p className="sub-content">Device means any device that can access
          the Service such as a computer, a cellphone or a digital tablet.</p>

          <p className="sub-content">Personal Data is any information that relates to an identified or
          identifiable individual.</p>
          <p className="sub-content"> Service refers to the Application.</p>
        </div>
        <div className="my-12 sub-content">
          Service Provider means any natural or legal person who processes the
          data on behalf of the Company. It refers to third-party companies or
          individuals employed by the Company to facilitate the Service, to
          provide the Service on behalf of the Company, to perform services
          related to the Service or to assist the Company in analyzing how the
          Service is used.
        </div>
          <div className="my-12 sub-content">
          Usage Data refers to data collected with the permission of the user,
        generated using the Service or from the Service infrastructure itself
        e.g. IP address.
          </div>
          <div className="my-12 sub-content">
          You means the individual accessing or using the
        Service, or the company, or other legal entity on behalf of which such
        individual is accessing or using the Service, as applicable.
          </div>
          <div className="my-12 sub-content">
          With
        Service Providers: We may share Your personal information with Service
        Providers to monitor and analyze the use of our Service, to contact You.
          </div>
          <div className="my-12 sub-content">
          For business transfers: We may share or transfer Your personal
        information in connection with, or during negotiations of, any merger,
        sale of Company assets, financing, or acquisition of all or a portion of
        Our business to another company.
          </div>

          <div className="my-12 sub-content">
          With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.
          </div>

          <div className="my-12 sub-content">
          With other users: when You share
        personal information or otherwise interact in the public areas with
        other users, such information may be viewed by all users and may be
        publicly distributed outside.
          </div>

          <div className="my-12 sub-content">
          With Your consent: We may disclose Your
        personal information for any other purpose with Your consent. Disclosure
        of Your Personal Data
          </div>

        <div className="my-12">
          <p className="lead">Retention of Your Personal Data</p>
         <p className="sub-content"> The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.</p>
           <p className="sub-content"> The Company will also
          retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period of time, except when this data
          is used to strengthen the security or to improve the functionality of
          Our Service, or We are legally obligated to retain this data for
          longer time periods.</p>
        </div>
        <div className="my-12">
          <p className="lead">Transfer of Your Personal Data</p>
         <p className="sub-content"> Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to - and maintained on - computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction. Your consent to this Privacy Policy followed by Your
          submission of such information represents Your agreement to that
          transfer. The Company will take all steps reasonably necessary to
          ensure that Your data is treated securely and in accordance with this
          Privacy Policy and no transfer of Your Personal Data will take place
          to an organization or a country unless there are adequate controls in
          place including the security of Your data and other personal
          information.</p>
        </div>
        <div className="my-12">
          <p className="lead"> Delete Your Personal Data</p>
         <p className="sub-content"> You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You. Our Service may give
          You the ability to delete certain information about You from within
          the service.</p>
        </div>
        <div className="my-12">
          <p className="lead">Service</p>
          <p className="sub-content">You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us. Please note,
          however, that We may need to retain certain information when we have a
          legal obligation or lawful basis to do so.</p>
        </div>
        <div className="my-12">
          <p className="lead">Other legal requirements</p>
          <p className="sub-content">The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to: Comply with a legal obligation.
          Protect and defend the rights or property of the Company. Prevent or
          investigate possible wrongdoing in connection with the Service.
          Protect the personal safety of Users of the Service or the public.
          Protect against legal liability.</p>
        </div>
        <div className="my-12">
          <p className="lead"> Business Transactions</p>
          <p className="sub-content">If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.</p>
        </div>
        <div className="my-12">
          <p className="lead">Law Enforcement</p>
          <p className="sub-content">Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).</p>
        </div>
       <div>
      <p className="lead"> Other legal requirements</p>
      <ul>
      The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
      <li className="sub-content">Comply with a legal obligation.</li>
      <li className="sub-content">Protect and defend the rights or property of the Company.</li>
      <li className="sub-content">Prevent or investigate possible wrongdoing in connection with the Service.</li>
      <li className="sub-content">Protect the personal safety of Users of the Service or the public.</li>
      <li className="sub-content">Protect against legal liability.</li>
      </ul>
       </div>
       


        <div className="my-12">
          <p className="lead">Security of Your Personal Data</p>
          <p className="sub-content">The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security. We use special and qualified data encryption
          methods to ensure your data is safe.</p>
        </div>
        
        <div className="my-12">
          <p className="lead"> Children's Privacy</p>
          <p className="sub-content">Our Service does not address anyone under the age of 16. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 16. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 16 without verification of parental consent, We take
          steps to remove that information from Our servers. If We need to rely
          on consent as a legal basis for processing Your information and Your
          country requires consent from a parent, We may require Your parent's
          consent before We collect and use that information.</p>
        </div>
        <div className="my-12">
          <p className="lead">Links to Other Websites</p>
          <p className="sub-content">Currently, our service is not affiliated with any 3rd party websites
          at this time.</p>
           <p className="lead">Changes to this Privacy Policy</p>
            <p className="sub-content">We may update Our Privacy
          Policy from time to time. We will notify You of any changes by posting
          the new Privacy Policy on this page. We will let You know via email
          and/or a prominent notice on Our Service, prior to the change becoming
          effective and update the "Last updated" date at the top of this
          Privacy.</p>
        </div>
        <div className="my-12">
          <p className="lead">Policy</p>
          <p className="sub-content">You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.</p>
          <p className="lead mt-8">Contact US</p>
         <p className="sub-content"> If you have any questions about this PrivacyPolicy, You can contact us
          on support@qollabs.org.</p>
        </div>
       
         
      </div>
    </div>
  );
}

export default Privacy;
