import React from 'react';
import QOL from "../assets/qol_logo.png";
import "../styles/contact.css"


function Delete() {
  return (
    <div className='block mx-auto py-12'>
        <div className='flex flex-row mx-auto mt-20 justify-center delete'>
        <img src={QOL} alt="logo"/>
        </div>
       <div className='flex flex-row mx-auto justify-center'>
       <form className='mx-auto py-6 block' action="https://formsubmit.co/info@qollabs.org" method="POST">
       <input type="hidden" name="_next" value="https://qollabs.org" />
        <input type="hidden" name="_captcha" value="false" />
            <div className='flex flex-row justify-center mx-auto'>
            <p className='header'> Delete Your Account</p>
        </div>

        <div className=' py-6 form-field'>
                <div className='flex flex-row mx-auto justify-center'>
                <label for="App_name" className='text-white block'>Please Input App Name</label>
                </div>
           <div className='flex flex-row mx-auto justify-center'>
           <input name='App_name' type='text' placeholder='Enter App Name'   className='text-white'/>
           </div>
           
            </div>

            <div className=' py-6 form-field'>
                <div className='flex flex-row mx-auto justify-center'>
                <label for="email_address" className='text-white block'>Please Input Your Email Address</label>
                </div>
           <div className='flex flex-row mx-auto justify-center'>
           <input name='email_address' type='email' placeholder='Enter your email address'   className='text-white'/>
           </div>
           
            </div>
           
             <div className=' py-6 form-field'>
                <div className='flex flex-row mx-auto justify-center'>
                <label for="password" className='text-white block'>Please Input Your Password</label>
                </div>
                <div className='flex flex-row mx-auto justify-center'>
                <input name='password' type='password' placeholder='Enter your password'  className='text-white'/>
                </div>
           
             </div>

             <div className='flex flex-row mx-auto justify-center text-red-500 text-xs'>
                <p>Are you sure you want to delete your account?<br/> This action cannot be revoked, once initiated</p>
                
             </div>
            <div className='delete flex flex-row mx-auto justify-center'>
            <button>Delete Account</button>
            </div>
          
        </form>
       </div>
        
    </div>
  )
}

export default Delete