import React, { useState } from "react";

import video from "../assets/ql_hero.webm";
import "../styles/home.css";
import Carousel from "./Carousel";
import { rewards } from "../components/Data";

import Pop from "../assets/figma.svg";
import Lp from "../assets/pad.png";
import Shield from "../assets/crowd.png";

import Adr from "../assets/airdrp.png";
import Mart from "../assets/mart.png";


import Phone from "../assets/qol_mockup.svg";
import Thumb from "../assets/thumb.png";
import Thingy from "../assets/redundant.png";
import Pay from "../assets/indicator.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faAndroid } from "@fortawesome/free-brands-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";

function Home() {
  

  const [isPimVisible, setIsPimVisible] = useState(false);

  const handlePimToggle = () => {
    setIsPimVisible(!isPimVisible);
  };

  const [isQolVisible, setIsQolVisible] = useState(false);

  const handleQolToggle = () => {
    setIsQolVisible(!isQolVisible);
  };

  const [isPymVisible, setIsPymVisible] = useState(false);

  const handlePymToggle = () => {
    setIsPymVisible(!isPymVisible);
  };

  const [isLaunchVisible, setIsLaunchVisible] = useState(false);

  const handleLaunchToggle = () => {
    setIsLaunchVisible(!isLaunchVisible);
  };

  const [isRewardVisible, setIsRewardVisible] = useState(false);

  const handleRewardToggle = () => {
    setIsRewardVisible(!isRewardVisible);
  };

  const [isPymAgain, setIsPymAgain] = useState(false);

  const handlePymAgain = () => {
    setIsPymAgain(!isPymAgain);
  };

  const [multipleAccounts, setMultipleAccounts] = useState(false);

  const handleMultipleAccounts = () => {
    setMultipleAccounts(!multipleAccounts);
  };

  return (
    <div className="home">
      <div>
        <div className="hero-section">
          <div className="hero-container">
            <video src={video} autoPlay loop muted />
            <div className="hero-text">
              <div data-aos="fade-left" data-aos-duration="1500">
                <div className="square">
                  <h1 className="text-5xl md:text-6xl lg:text-6xl font-bold  text-white-700 p-2 my-24">
                    Experience The Power Of Web3 <br /> Innovation
                  </h1>
                </div>
              </div>

              <div className="hero-button justify-center mt-28">
                <a href="https://t.me/qollabs">
                  <button className="list button">Join Our Community</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" mx-auto w-full">
        <div className="section md:flex md:flex-row lg:flex lg:flex-row justify-between mx-auto bg-black">
          <div className="text md:w-3/6 lg:w-3/6 p-4 md:ml-40 lg:ml-40 my-auto">
            <p className="grade text-pretty text-xl px-3 py-2  text-center md:text-start lg:text-start my-2">
              Our Vision
            </p>
            <p className="grade text-center md:text-start lg:text-start px-3 font-black text-2xl">
              Empowering startups to soar while connecting individuals with
              life-changing opportunities
            </p>

            <p className="my-3 text-pretty text-sm px-3 text-white text-center md:text-start lg:text-start">
              Welcome to Qol Labs, where Web3 innovation transforms dreams into
              reality.
            </p>
            <div className=" flex justify-center mx-auto md:mx-3 lg:mx-3 md:block lg:block">
              <a href="https://t.me/qollabs">
                <button className="list button">Join Our Community</button>
              </a>
            </div>
          </div>
          <div className="pop-up flex flex-row justify-center mx-auto my-1 h-full w-full">
            <div className="md:mr-28 lg:mr-28">
              <img
                src={Pop}
                alt="mobile view"
                className="md:mr-28 lg:mr-28 w-full md:w-1/2 lg:w-1/2 xl:w-1/2"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center mx-auto w-full my-14">
        <div className="block mx-auto theme">
          <div className=" my-28">
            <p className="text-center text-base font-medium mt-3 mb-6">
              Enter The Qol Hub
            </p>
            <p className="font-black grade text-5xl text-center mt-3 mb-8 text-wrap">
              The Sound of Crypto <br /> Freedom
            </p>
          </div>
          <div className="md:grid md:grid-cols-2 gap-64 mx-auto mt-8 lg:grid lg:grid-cols-2 block w-3/6">
            <div className="text-white w-full sound">
              <div className="flex flex-row justify-center mx-auto">
              <img src={Lp} alt="pym token" />
              </div>
              
              <p className="font-bold text-2xl text-center">Launchpad</p>
              <p className="text-white font-normal text-base text-center">
                Explore and secure potential tokens before they hit the market.
              </p>
            </div>
            <div className="text-white w-full sound">
            <div className="flex flex-row justify-center mx-auto">
            <img src={Shield} alt="pym token" />
                </div>
              
              <p className="font-bold text-2xl text-center">Crowdfunding</p>
              <p className="text-white  font-normal text-base text-center">
                Invest in promising startups and early-stage companies.
              </p>
            </div>
            <div className="text-white w-full sound">
            <div className="flex flex-row justify-center mx-auto">
            <img src={Mart} alt="pym token" />
                </div>
              
              <p className="font-bold text-2xl text-center">Web3 Wallet</p>
              <p className="text-white  font-normal text-base text-center">
              Unlock the power of Web3 and take control of your digital assets with a secure, user-friendly wallet.
              </p>
            </div>

            <div className="text-white w-full sound">
            <div className="flex flex-row justify-center mx-auto">
            <img src={Adr} alt="pym token" />
                </div>
              
              <p className="font-bold text-2xl text-center">Airdrop</p>
              <p className="text-white  font-normal text-base text-center">
                Discover and farm verified airdrops in the crypto space and
                unlock opportunities to obtain tokens and rewards.
              </p>
            </div>
            
          </div>
        </div>
      </div>
      <div className=" flex flex-row justify-center mx-auto">
        <a href="https://t.me/qollabs">
          <button className="list button">Join Our Community</button>
        </a>
      </div>

      <div className="break bg-transparent rounded-xl p-8 mx-auto lg:w-full md:w-full md:my-10 lg:my-10 my-4 ">
        <h3 className="grand text-center text-4xl  lg:text-nowrap md:text-5xl lg:text-5xl font-black text-wrap">
          Building a Decentralized Future
        </h3>
      </div>

      <div className="bg-black mb-40 mt-32">
        <Carousel images={rewards} />

        

       

      </div>

      <div className="pay_area hidden  md:hidden lg:block">
        <div
          className="flex flex-row content-center justify-center mx-auto my-auto"
          id="trial"
        >
          <div className="blue  py-20 px-10 bg-blue-900 rounded-2xl flex flex-row justify-between">
            <div className="first_half w-1/2 p-4">
              <p className="font-black text-white lg:text-5xl  text-start my-5">
                Get web3 <br /> freedom today
              </p>
              <div className="flex flex-row justify-start gap-10">
                <a href="https://play.google.com/store/">
                  <button className="store text-nowrap lg:text-base md:p-2.5 sm:text-xs sm:p-2 bg-white text-teal-700 rounded-2xl">
                    <FontAwesomeIcon
                      icon={faApple}
                      className="apple text-white p-1"
                    />{" "}
                    Download for IOS
                  </button>
                </a>
                <a href="https://play.google.com/store/">
                  <button className="store text-nowrap lg:text-base md:p-2.5 sm:text-xs sm:p-2 bg-white text-teal-700 rounded-2xl">
                    <FontAwesomeIcon
                      icon={faAndroid}
                      className="apple text-white p-1"
                    />{" "}
                    Download for Android
                  </button>
                </a>
              </div>
            </div>

            <div className="w-2/6 mockup">
              <div id="phone">
                <img src={Phone} alt="qol super app" />
                <div id="thumbs">
                  <img src={Thumb} alt="thumbs-up" />
                </div>
                <div id="orb">
                  <img src={Thingy} alt="shiny blue orb" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pay_area_md hidden  md:block lg:hidden">
        <div
          className="flex flex-row content-center justify-center mx-auto my-auto"
          id="trial"
        >
          <div className="blue_md  py-20 px-10 bg-blue-900 rounded-2xl flex flex-row justify-between">
            <div className="first_half w-1/2 p-4">
              <p className="font-black text-white text-5xl text-start my-5">
                Get web3 <br /> freedom today
              </p>
              <div className="flex flex-row justify-start gap-8">
                <a href="https://play.google.com/store/">
                  <button className="store text-nowrap md:p-2.5 text-base bg-white text-teal-700 rounded-2xl">
                    <FontAwesomeIcon
                      icon={faApple}
                      className="apple text-white p-1"
                    />{" "}
                    Download for IOS
                  </button>
                </a>
                <a href="https://play.google.com/store/">
                  <button className="store text-nowrap md:p-2.5 text-base bg-white text-teal-700 rounded-2xl">
                    <FontAwesomeIcon
                      icon={faAndroid}
                      className="apple text-white p-1"
                    />{" "}
                    Download for Android
                  </button>
                </a>
              </div>
            </div>

            <div className="w-2/6 mockup">
              <div id="phone_md">
                <img src={Phone} alt="qol super app" />
                <div id="thumbs_md">
                  <img src={Thumb} alt="thumbs-up" />
                </div>
                <div id="orb_md">
                  <img src={Thingy} alt="shiny blue orb" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pay_area_sm block md:hidden lg:hidden">
        <div
          className="flex flex-row content-center justify-center mx-auto my-auto"
          id="trial"
        >
          <div className="blue_sm  py-20 px-5 bg-blue-900 rounded-2xl flex flex-row justify-between">
            <div className="first_half w-1/2 p-2">
              <p className="font-black text-white text-2xl text-start my-5">
                Get web3 <br /> freedom today
              </p>
              <div className="flex flex-row justify-start gap-2">
                <a href="https://play.google.com/store/">
                  <button className="store_sm text-nowrap p-1 bg-white text-teal-700 rounded-2xl">
                    <FontAwesomeIcon
                      icon={faApple}
                      className="apple_sm text-white p-1"
                    />{" "}
                    Download for IOS
                  </button>
                </a>
                <a href="https://play.google.com/store/">
                  <button className="store_sm text-nowrap p-1 bg-white text-teal-700 rounded-2xl">
                    <FontAwesomeIcon
                      icon={faAndroid}
                      className="apple_sm text-white p-1"
                    />{" "}
                    Download for Android
                  </button>
                </a>
              </div>
            </div>

            <div className="w-1/4 mockup">
              <div id="phone_sm">
                <img src={Phone} alt="qol super app" />
                <div id="thumbs_sm">
                  <img src={Thumb} alt="thumbs-up" />
                </div>
                <div id="orb_sm">
                  <img src={Thingy} alt="shiny blue orb" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      <div className="faq-section container block p-12  mx-auto my-8 lg:w-8/12">
        <div className="lg:w-6/6 mt-3 mb-8">
          <p className="text-wrap text-4xl text-center md:text-5xl lg:text-5xl md:text-nowrap lg:text-nowrap font-normal">
            Frequently Asked Questions
          </p>
        </div>

        <div className="qa lg:w-full justify-center">
          <div className="faq  text-center text-pretty rounded-md bg-transparent mx-auto text-white md:w-full border-gray-300  border-2 p-4">
            <p className="text-base flex flex-row justify-between font-bold">
              What is Qol Super App?{" "}
              <span className="text-end font-bold">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={handlePimToggle}
                />
              </span>
            </p>

            <p
              className="text-base p-8 text-center font-bold"
              style={{ display: isPimVisible ? "block" : "none" }}
            >
              The Qol Super App, crafted by Qol Labs, serves as an extensive
              web3 project platform. It integrates airdrops, launchpad services,
              and crowdfunding features to offer a robust experience. This
              innovative platform fosters community engagement with top-tier
              web3 projects through intuitive interactions such as watching ads,
              participating in polls and consuming content. Users earn crypto
              tokens as rewards, enhancing engagement within a dynamic and
              incentivized ecosystem.
            </p>
          </div>
          <div className=" text-center text-pretty rounded-md bg-transparent mx-auto text-white md:w-full border-gray-300 border-2 p-4 my-2">
            <p className="text-base flex flex-row justify-between font-bold">
              What are Qol Points?{" "}
              <span className="font-bold">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={handleQolToggle}
                />
              </span>
            </p>
            <p
              className="text-base p-8 text-center font-bold"
              style={{ display: isQolVisible ? "block" : "none" }}
            >
              The Qol points are rewards in the Qol Super App earned by users
              for their engagement and loyalty. Here is how it works: users
              accumulate Qol points based on their activities within the app,
              reflecting their potential earnings over time by viewing ads,
              participating in polls and other activities within the Qol Super
              App.
            </p>
          </div>

          <div className="text-center text-pretty rounded-md bg-transparent mx-auto text-white md:w-full border-gray-300 border-2 p-4 my-2">
            <p className="text-base flex flex-row justify-between font-bold">
              {" "}
              How can I earn rewards?{" "}
              <span className="font-bold">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={handleRewardToggle}
                />
              </span>
            </p>
            <p
              className="text-base p-8 text-center font-bold"
              style={{ display: isRewardVisible ? "block" : "none" }}
            >
              Sign up and gain early access on Qol Super App by completing tasks
              in the app, on the ads sessions that occurs twice daily and by
              filling out surveys from our partner's campaigns.
            </p>
          </div>

          <div className="text-center text-pretty rounded-md bg-transparent mx-auto text-white md:w-full border-gray-300 border-2 p-4 my-2">
            <p className="text-base flex flex-row justify-between font-bold">
              What is Qol Launchpad?{" "}
              <span className="font-bold">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={handleLaunchToggle}
                />
              </span>
            </p>
            <p
              className="text-base p-8 text-center font-bold"
              style={{ display: isLaunchVisible ? "block" : "none" }}
            >
              This is a crypto incubator and decentralized exchange (DEX)
              platform that provides crypto projects with a distinctive chance
              to secure funding and access early-stage investors. Operating on
              genuinely decentralized principles, unlike conventional venture
              capital firms or initial coin offerings (ICOs), Qol LaunchPad
              ensures transparency and fairness throughout the token sale
              process. It enables projects to gather essential early-stage
              funding from investors, empowering them to garner support and
              momentum prior to public launch.
            </p>
          </div>

          <div className="text-center text-pretty rounded-md bg-transparent mx-auto text-white md:w-full border-gray-300 border-2 p-4 my-2">
            <p className="text-base flex flex-row justify-between font-bold">
              Who can sign up for the Qol Super App?{" "}
              <span className="font-bold">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={handlePymAgain}
                />
              </span>
            </p>
            <div
              className="text-base p-8 text-center font-bold"
              style={{ display: isPymAgain ? "block" : "none" }}
            >
              Everyone can sign up for Qol Super App by registering on the app.
            </div>
          </div>

          <div className="text-center text-pretty rounded-md bg-transparent mx-auto text-white md:w-full border-gray-300 border-2 p-4 my-2">
            <p className="text-base flex flex-row justify-between font-bold">
              Who can participate in the Qol Airdrop?{" "}
              <span>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={handlePymToggle}
                />
              </span>
            </p>
            <div
              className="text-base p-8 text-center font-bold"
              style={{ display: isPymVisible ? "block" : "none" }}
            >
              The Qol Airdrop on the Qol Super App is limited to 2,100,000
              participants.
            </div>
          </div>

          <div className="text-center text-pretty rounded-md bg-transparent mx-auto text-white md:w-full border-gray-300 border-2 p-4 my-2">
            <p className="text-base flex flex-row justify-between font-bold">
              {" "}
              Can I create multiple accounts?{" "}
              <span className="font-bold">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={handleMultipleAccounts}
                />
              </span>
            </p>
            <p
              className="text-base p-8 text-center font-bold"
              style={{ display: multipleAccounts ? "block" : "none" }}
            >
              No. Users are prohibited from having multiple accounts, having
              multiple accounts will lead to a ban on such user(s) from
              accessing the Qol Super App.
            </p>
          </div>
        </div>
      </div>


      <div className="community bg-black">
        <div className="group block lg:flex lg:flex-row mt-20">
          <div className=" lg:w-4/6 lg:p-40 justify-center">
            <p className="grade text-start md:text-start lg:text-start lg:p-20 pt-40 pb-20 px-10 font-black lg:text-5xl text-5xl text-pretty">
              Meet the worldwide community.
            </p>

            <p className="text-base lg:px-24 text-wrap text-start pl-10 pb-6">
              Join a fast-growing community of <br /> pioneers and innovators
              <br />
              connected all over the world, building the new
              <br /> era of the internet.
            </p>
          </div>
          <div className=" lg:w-3/6 lg:p-40 text-start ">
            <div className="mb-12">
              <div className="flex flex-row mb-3 mx-auto justify-start">
                <p className="font-bold text-2xl lg:mr-3 text-start pl-10 pr-2 lg:px-1 lg:text-start">
                  Community Chat
                </p>
                <img src={Pay} alt="arrow up" id="indicate" />
              </div>

              <p className="text-base sub px-10 lg:px-1 lg:text-start">
                Ask general questions and chat <br />
                with the worldwide community on Telegram.
              </p>
            </div>

            <div className="mb-12">
              <div className="flex flex-row mb-3 mx-auto justify-start">
                <p className="font-bold text-2xl lg:mr-3 text-start pl-10 pr-2 lg:px-1 lg:text-start">
                  Twitter
                </p>
                <img src={Pay} alt="arrow up" id="indicate" />
              </div>

              <p className="text-base sub px-10 lg:px-1 lg:text-start">
                Follow @qollabs to get <br />
                the latest news and updates <br />
                from across the ecosystem.
              </p>
            </div>
            <div className="mb-12 pb-12">
              <p className="font-bold text-2xl px-10 lg:px-1 lg:text-start">
                Discord
              </p>
              <p className="text-base sub px-10 lg:px-1 lg:text-start">
                Have technical questions about Qol Labs?
                <br /> Ask a developer on the Discord.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:flex lg:flex-row lg:justify-center lg:mx-auto pl-10">
          <div className="block mb-3 mr-12">
            <p className="font-bold text-2xl">Receive Updates</p>
            <p className="text-sm ">Unsubscribe at any time.</p>
          </div>

          <div className="mb-3 mx-auto lg:mx-0 md:mx-0">
            <input className="mail" placeholder="Your email" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
