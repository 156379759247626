import React from "react";
import Tkn from "../assets/tkn.png";
import "../styles/carousel.css";

function CarouselItemTwo() {
  return (
    <div className="bg-black block md:flex lg:flex md:flex-row lg:flex-row justify-center mx-auto">
      <div className="w-full md:w-3/6 lg:w-3/6 h-3/6 md:h-full lg:h-full flex flex-row mx-auto justify-center my-3">
        <div className="flex flex-row justify-center mx-auto">
        <img src={Tkn} alt="phone mockup" id="tkn" className="mx-auto object-fit: contain object-center" />
        </div>
        
      </div>
      <div className="w-full md:w-3/6 lg:w-3/6 block md:px-12 lg:px-12">
        <p className="text-white grade font-black md:text-5xl lg:text-5xl text-3xl md:py-12 lg:py-12 py-2 text-center">
          PIM Protocol
        </p>
        <p className="text-pretty text-white  md:text-2xl lg:text-2xl text-base md:py-12 lg:py-12 py-2 text-center">
        Pim Protocol is an advanced blockchain technology designed to be efficient and compatible with mobile and IoT devices.
        </p>
        <div className="hero-button flex flex-row justify-center mt-2 mx-auto">
          <a href="https://qollabs.xyz/waitlist/">
            <button className="list button">Coming Soon</button>
          </a>
        </div>
      </div>
      
    </div>
  );
}

export default CarouselItemTwo;
