import React from 'react';
import "../styles/privacy.css"

function Terms() {
  return (
    <div className="my-20">
    <div className=" block md:p-36 lg:p-36 p-10 mx-auto md:w-4/6 lg:w-4/6">
      <div className='terms'>
          
        
          <h3>Qol Super App Terms Of Use</h3>
          <p className='lead'>
            Welcome to Qol Super App Terms of Use (these "Terms").</p>
            <p className='sub-content'> These Terms are important because they describe 
            your rights and obligations when using the Services (defined herein ) offered by us or our affiliates.
          </p>
          <p className='sub-content'>
            Kindly note that these Terms contain Indemnification, Governing Law, Dispute Resolution, Assumption of 
            Risk and Disclaimer of Warranties and Limitation of Liability sections that affect your rights, 
            including your ability to bring legal claims against us. Please read these Terms, our Privacy Policy 
            (the "Privacy Policy") and any other terms referenced in this document carefully. If you do not agree 
            to be bound by these Terms of Use, you are not permitted to use our Services. We recommend that you 
            save and print a copy of these terms for future reference.
          </p>
        

        
          <p className='lead'>Introduction</p>
          <p className='sub-content'>
            Welcome to Qol Super App, operated by Qol Labs Ltd.(Herein our Address) ("Qol Supper App", "we", "Us" 
            "our", a product and/or Affiliate of “Qol Labs”). These Terms govern your access to and use of the Qol 
            Labs website ("Website") and/or the mobile or desktop software application Qol Super App ("App"), 
            (collectively, the "Services"). By signing up to use an account, you agree to be bound by the terms 
            and conditions contained in these Terms, including Section 10 ('Dispute Resolution), as well as the 
            Privacy Policy. Qol Super App is an all-in-one platform created by Qol Labs to support startups by 
            offering services like Airdrop, Launchpad, and Crowdfunding. It is designed to be a comprehensive tool 
            for new businesses to conduct market research, gain exposure, raise funds, and launch their projects. 
          </p>
          <p className='lead my-6'>
            WE ARE NOT A BROKER, FINANCIAL INSTITUTION OR CREDITOR. THE SERVICES ARE OFFERED AS THE PURPOSE 
            MENTIONED ABOVE ONLY.
          </p>

          <h4>Using Our Services</h4>
          <p className='lead'>Important disclaimers</p>
          <p className='sub-content'>
            Qol Super App is not intended as, and does not provide, any investment or financial 
            advice whatsoever. As with any financial or investment decisions, you should conduct your own research and 
            due diligence investigation, to properly evaluate the benefits and risks of any investment or financial 
            transaction. You should also seek the advice and guidance of qualified accountants, financial advisors, 
            tax advisors, legal counsels and investment advisors, in connection with any investment or financial 
            transaction. 
          </p>

          <p className='lead'>Eligibility</p>
          <p className='sub-content'>
            You may not use the Services if you are below the legal age in your jurisdiction or it is otherwise illegal in 
            your jurisdiction for you to access, div and/or use the Services. If any of these conditions apply to you, 
            please leave this Site immediately. If you do not leave this Site, then you will be deemed for all purposes to 
            have represented that none of these conditions apply to you.
          </p>

          <p className='lead'>Additional Eligibility</p>
          <p className='sub-content'>
            Certain of our Services may impose additional eligibility requirements. For example, participating in the Game, 
            surveys  and receiving Points (each, as defined below) related to the Services will require that you verify 
            your account with our third party verification provider. To verify your account, you will have to submit your 
            picture and consent to the use of facial recognition software. The additional required information and 
            eligibility requirements will be communicated to you when signing up or entering a Service that imposes 
            additional requirements and may be updated at any time. Furthermore, certain features may be unavailable to 
            users in certain jurisdictions.
          </p>

          <p className='lead'>Updates to Terms of Use</p>
          <p className='sub-content'>
            Your use of the Services is subject to your agreement to these Terms, which may be updated by us at 
            any time with or without notice to you. The Effective Date of the most up to date Terms will be 
            provided at the top of each version of these Terms, respectively. Any such updates will be effective 
            upon our publishing such updated Terms. If you do not agree with these Terms, you may not use the 
            Services. Updates to the Services. We may update and change our Services from time to time, for 
            example, to reflect changes to our products, our users' needs and our business priorities. We will try 
            to give you reasonable notice of any major changes.
          </p>

          <p className='lead'>Users</p>
          <p className='sub-content'>
            In order to use certain portions of our Services, you will need to create an account ("User Account"). 
            In order to create a User Account, you must log in through one of the third party services (such as 
            Facebook, Google, Apple ( Connected Account) or sign up by providing the required information, such 
            as your email address, and complete any additional required steps, such as account verification. 
          </p>
          <p className='lead'>By creating a User Account, you agree:</p>
          
            
            <p className='sub-content'>
              to provide accurate, current and complete information about yourself;
            </p>
          
          
            
            <p className='sub-content'>
              to maintain and promptly update from time to time as necessary your information;
            </p>
        
          
          
            <p className='sub-content'>
              to maintain the security of your password and accept all risks of unauthorized access involving your 
              email and password combination and the information you provide to us;
            </p>
          
          
            
            <p className='sub-content'>
              to immediately notify us if you discover or otherwise suspect any security breaches related to the Services, 
              or your email and password combination;
            </p>
          
          
           
            <p className='sub-content'>
              that you will use our Services only for yourself, and not on behalf of any third party, unless authorized by 
              such third party; and 
            </p>
          
          
            
            <p className='sub-content'>
              that you are fully responsible for all activity on the Services that occurs under your User Account.
            </p>
          

          <p className='lead'>Because of the Nature of  the Qol Super App we might need the following  Data from our Users:</p>
          
            
            <p className='sub-content'>
              Date of Birth or Age;
            </p>
          
          
            
            <p className='sub-content'>
              Name (Full Name);
            </p>
        
          
            
            <p className='sub-content'>
              Country, State , Region or Province and/or LGA;
            </p>
          
          
            
            <p className='sub-content'>
            Facial Verification ( reference to Required Laws);
            </p>
          
          
            
            <p className='sub-content'>
              Official or Government Approved ID (National ID, International Passport, Driver's license);
            </p>
          
        
            
            <p className='sub-content'>
              Quarter Facial Verification to ensure the data is tamper proof.
            </p>
          
        
        
          <p className='sub-content'>
            These can be implemented on the next version if they are not there already.
          </p>
        
          
        <div>
          <p className='sub-content'>
            The Qol Super App will be used to conduct study and marketing surveys and polls to understand market dynamic 
            on a sample population at any given time. Because of our Privacy policies, such surveys might be conducted 
            anonymously. So that users can decide to join the survey anonymously by the click of a button.
          </p>
        </div>

        <div>
          <p className='sub-content'>
            We may, in our sole discretion, refuse to allow you to create a User Account, or limit the number of User 
            Accounts that you may associate with the Services, or suspend or terminate any User Account or access to the 
            Services.
          </p>
        </div>

        <div>
          <ul className="sub-content">You also agree that you will not:</ul>
          
            
            <li className='sub-content'>Create multiple User Accounts;</li>
          

          
           
            <li>
              Use the Services under a different User Account if we've disabled access for you under a different 
              User Account, unless you have our written permission first;
            </li>
          

          
            
            <li>
              Buy, sell, rent, or lease access to the Services under your User Account to any third party, unless you have 
              our written permission first;
            </li>
          

          
           
            <li>
              Share your email and password combination or access to the Connected Account with anyone; or
            </li>
        

            <li> Log in or attempt to log in to access the Services through any unauthorized third party application(s or clients).</li>
        </div>

        <div>
          <p className='lead'>Account Communication</p>
          <p className='sub-content'>
            By creating a User Account, you agree to receive service related electronic communications from us. You 
            agree that any notices, agreements, disclosures, or other communications that we send to you electronically 
            will satisfy any legal communication requirements, including, but not limited to, that such communications 
            be in writing. You may opt out of receiving promotional emails that you have previously opted-in to at any 
            time by following the instructions to unsubscribe, as provided therein.
          </p>
        </div>

        <div >
          <p className='lead'>Chat</p>
          <p className='sub-content'>
            You may not access or use the chat function for any purpose other than that for which we make the our 
            Services available.
          </p>
          <ul>
          
            You agree not to use the chat for any of the following purposes including, but not limited to:
          
          
            
            <li>
              Collecting usernames and/or email addresses of users to send unsolicited email or creating user accounts 
              under false pretenses.
            </li>
          
          
            
            <li>
              Circumventing, disabling, or otherwise interfering with security-related features of the Service, including 
              features that prevent or restrict the use or copying of any content or enforce limitations on the use.
            </li>
          
          
            
            <li>
              Trick, defraud, or mislead us and/or other users, especially in any attempt to learn sensitive account 
              information such as user passwords
            </li>
          
          
            
            <li>
              Engage in any automated use of the system, such as using scripts to send comments or messages, or using any 
              data mining, robots, or similar data gathering and extraction tools.
            </li>
          
          
            
            <li>
              Interfere with, disrupt, or create an undue burden on the Service or the networks and services 
              connected to the Service.
            </li>
          
          
            
            <li>
              Attempt to impersonate another user or person, or use the username of another user
            </li>
          
         
            
            <li>
              Use any information obtained from the Service in order to harass, abuse, or harm another person.
            </li>
          
         
            
            <li>
              Harass, annoy, intimidate, or threaten any of our employees, agents, or other users.
            </li>
          
          
            <li>
              Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material that 
              interferes with any party's uninterrupted use and enjoyment of the Site, or any material that acts as a 
              passive or active information collection or transmission mechanism.
            </li>
          
          
            <li>
              Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material that 
              interferes with any party's uninterrupted use and enjoyment of the Site, or any material that acts as a 
              passive or active information collection or transmission mechanism.
            </li>
          
          
            <li>
              Falsely imply a relationship with us or another company with whom you do not have a relationship.
            </li>
          
          </ul>
        </div>

        <div>
          <div className='lead'>Airdrop Activities</div>
          <div className='sub-content'>
            Qol super app offers access to airdrops(defined below). Airdrops are operated by Qol Labs or third parties we 
            collaborate with. The rules described in this section apply to all users participating in the airdrop 
            claiming sessions.
          </div>
          <div className='sub-content'>
            However, certain aspects of this airdrop may have additional rules or requirements, which will be provided 
            to you if you chose to participate in them.
          </div>
          <div className='lead'>The Airdrop</div>
          <div className='sub-content'>
            Within the App, Users can enroll in certain activities ("Qol token airdrop tasks") and earn "Points".
          </div>
          <div className='sub-content'>
            Airdrops are not limited to Qol labs and could be organized and operated by third parties we collaborate 
            with (each, "Game Organizer").
          </div>
          <div className='sub-content'>
            Points earned are determined by your participation in the Game, such as completing certain tasks, 
            referring a friend, completing surveys or logging in daily. Some recurring tasks may result in regular 
            Points awards based on your participation level (your "Earning Frequency"). The Game Organizer 
            determines the precise method for calculating Earning Frequency, and those methods will be displayed 
            to you when you decide to participate in a Game. Points have no value. Some Games, as decided by each 
            Game Organizer, may result in distributions o cryptographic tokens or other blockchain-based assets 
            ("Tokens") by the Game Organizer directly to a User Wallet, based on the amount of Points you have 
            accumulated. Whether a Game offers Tokens will be disclosed to you when you chose to participate in a 
            Game.
          </div>

          <div className='lead'>Disclaimers</div>
          <div className='sub-content'>
            Your Earning Frequency and Points are not an asset, have no monetary value, and are non-transferrable. Qol 
            Labs reserves the right to delete, reduce, or restrict Your Earning Frequency, in accordance with these 
            Terms. Additionally, Qol Labs  reserves the right to withhold or restrict participation in the airdrop 
            process,Game or accruing of any Points to comply with any applicable laws or regulations, as determined by 
            us in our sole discretion.
          </div>
          <div className='sub-content'>
            Qol Labs  makes no offer or invitation to acquire, purchase, transfer, sell, or otherwise deal in any 
            cryptographic tokens or other blockchain-based asset, including any Tokens that may be offered by Game 
            Organizers. Game Organizers are responsible or views expressed therein do not necessarily reflect those 
            of Qol Labs and/or Qol Super App. Qol Super App is not responsible for the success or authenticity of 
            any project and offering a Game on the App is not an endorsement of the Game or the Game Organizer.
          </div>

          <div className='lead'>Tax Obligations</div>
          <div className='sub-content'>
            Use of the App, including your participation in the Airdrops , Surveys, Game and potentially receiving 
            Tokens, may result in various tax consequences, such as income or capital gains tax, value-added tax, goods 
            and services tax, or sales tax in certain jurisdictions. It is your responsibility to determine whether taxes 
            apply to any transactions you initiate or receive and, if so, to report and/or remit the correct tax to the 
            appropriate tax authority.
          </div>

          <div className='lead'>Additional Requirements</div>
          <div className='sub-content'>
            There may be additional eligibility requirements in order to receive Tokens from a Game. For example you may 
            need to comply with certain 'Know You Customer' obligations required by us or Game Organizer. Additionally, 
            you will need to have a compatible and supported cryptographic wallet (a "Wallet").
          </div>

          <div className='lead'>
            When using a Wallet in connection with a Game or the Services, you agree:
          </div>

          
            <div className='sub-content'>
              we and the Game Organizer shall not be responsible to secure your private keys, seed words, 
              credentials or other means of authorization of your Wallet(s);
            </div>
          

         
            
            <div className='sub-content'>
              You own and control any Wallet you use in connection with our Services and you are responsible for 
              implementing all appropriate measures for securing any Wallet you use, including any private key(s), 
              seed words, credentials or other means of authorization necessary to access such storage 
              mechanism(s); and
            </div>
          

          
           
            <div className='sub-content'>
              we and the Game Organizer will not have any liability for any security breach or other act or 
              omission, or any other incident or circumstance, which result in your loss of access or custody of 
              any assets stored in your Wallet.
            </div>
          

          <div className='lead '>
            QOL SUPER APP will NEVER ask for your private keys, seed words, or the secret recovery phrase 
            associated with your wallet.
          </div>
        </div>

        <div>
          <div className="lead">Termination of Access to Service</div>
          <div className='sub-content'>
          <ul>
            Suspension, Termination, and Cancellation. Qol Labs  may, at its option and in its sole discretion:
            suspend, restrict, or terminate your access to any or all of the Services, and/or deactivate or cancel your User Account, if:
          
            
            <li>
              we are so required by a facially valid subpoena, court order or binding order of any government 
              authority;
            </li>

            <p>we reasonably suspect you of using the Services in connection with any Prohibited Uses (as defined 
              below);</p>
            <li>your use of the Services is subject to any pending litigation, investigation, or government proceeding and/or 
              we, in our sole discretion, perceive a heightened risk of legal or regulatory noncompliance associated with 
              your activity;</li>
            <li> any of our service partners are unable to support your use thereof;</li>
            <li> you take any action that Qol Labs deems in its sole discretion as circumventing Qol Labs controls, or abusing 
              promotions which Qol Super App may offer from time to time; or you breach these Terms.</li>
          
          

          
           
            
              
            
          
          </ul>
          
          </div>

          

          <div className="sub-content">
            If we suspend or close your User Account or terminate your use of the Services for any reason, we 
            may based on our discretion provide you with notice of our actions, unless a court order or other 
            legal process prevents or prohibits Qol Labs from providing you with such notice. You acknowledge 
            that Qol Labs decision to take certain actions, including limiting access to or suspending your User 
            Account, may be based on confidential criteria that are essential to Qol Labs risk management and/or 
            security protocols. You agree that Qol Super App /Qol Labs is under no obligation to disclose the 
            details of its risk management and/or security procedures to you.
          </div>

        </div>

        <div >
          <h4>User Conduct</h4>
          <div className='lead'>Prohibited Uses</div>
          <div className='sub-content'>
            You may not use the Services to engage in the following categories of activity ("Prohibited Uses"). 
            The Prohibited Uses listed below are representative, but not exhaustive, and Qol Labs reserves the 
            right to update or amend such list in its sole discretion at any time, with or without notice to you. 
            If you are uncertain as to whether or not your use of the Services involves a Prohibited Use or have 
            questions about how these requirements apply to you, please contact us.
          </div>

          <div className='sub-content'>
            By creating a User Account, you agree that you will not use your User Account to do or undertake any of the 
            following, as determined by Qol Labs in its sole discretion:
          </div>

          <div className='lead'>Abusive Activity:</div>
          <ul className='sub-content'>
            Actions which:
             
            
            <li>
              conduct, facilitate, authorize, or permit any div or data mining or web scraping;
            </li>
          
          
            
            <li>
              impose an unreasonable or disproportionately large load on our infrastructure, or detrimentally interfere 
              with, intercept, or expropriate any system, data, or information;
            </li>
          
        
            
            <li>
              transmit or upload any material to the Services that contains viruses, Trojan horses, worms, or any 
              other harmful or deleterious programs;
            </li>
          
          
            
            <li>
              attempt to gain unauthorized access to the Services, other User Accounts not belonging to you, computer 
              systems or networks connected to the Services, through password mining or any other means;
            </li>
          
          
            
            <li>
              use the email and password combination of any third party to access or use the Services, except in 
              the case of applications which are specifically authorized by a third party to access such third 
              party's User Account and information; or
            </li>
          
          
        
            <li>
              transfer your account access or rights to your account to a third party, unless by operation of law or 
              with the express permission of Qol Labs.
            </li>
          </ul>

          
          </div>

          <div>
        
            <div>
             <p className='lead'> Unlawful Activity</p> 
             <p className='sub-content'>
             Activity which would violate, or assist in the violation of, any law, statute, 
              ordinance or regulation. sanctions programs administered in any of the countries where Qol Labs 
              conducts business, including but not limited to the U.S.
             </p>
            </div>
          </div>
          <div>
            
            <p className='sub-content'>
              Department of Treasury's Office of Foreign Assets Control (OFAC), or which would involve proceeds of 
              any unlawful activity.
            </p>
          </div>
          <div>
            
            <div>
              <p className='lead'>Abuse Other Users:</p>
              <p className='sub-content'> Interfere with another individual's access to or use of the Services; defame, 
              abuse, extort, harass, stalk, threaten, or otherwise violate or infringe the legal or other rights 
              (such as, but not limited to, rights of privacy, publicity, and intellectual property) of others; or 
              harvest or otherwise collect information from the Services about others, including, without 
              limitation, email addresses, without proper consent.</p>
            </div>
          </div>
          <div>
            
            <div>
              <p className='lead'>Fraud:</p> 
             <p className='sub-content'> Activity which operates to defraud Qol Labs, any Qol Super App user(s) or any other person, 
              or provide any false, inaccurate, or misleading information to Qol Labs.</p>
            </div>
          </div>
        

        <div>
          <h4>Copyright and License to You</h4>
          <div className='lead'>Information Ownership</div>
          <div className='sub-content'>
            Unless otherwise indicated in writing by us, the Services and all content and other materials 
            contained therein, including, without limitation, the Qol Labs  logo and all designs, div, graphics, 
            trademarks, pictures, information, data, software, sound files, and/or other files related thereto 
            and/or associated therewith and the selection and arrangement thereof (collectively, "Content") are 
            and shall remain the sole and proprietary property of Qol Labs or our affiliates or licensors, if and 
            as applicable.
          </div>

          <div className='lead'>Third Party Intellectual Property</div>
          <div className='sub-content'>
            Notwithstanding anything to the contrary in these Terms, the Services and Content may include software 
            components provided by Qol Labs (Qol Super App)or its affiliates or a third party that are subject to 
            separate license terms, in which case those license terms will govern the usage of such software components, 
            as applicable.
          </div>

          <div className='lead'>Third Party Services</div>
          <div className='sub-content'>
            The Services may contain links to third-party websites ("Third-Party Websites") and/or applications 
            ("Third-Party Applications").
          </div>
          <div className='sub-content'>
            When you click on a link to a Third-Party Website or Third-Party Application, we will not warn you that you 
            have left the Services and are subject to the terms and conditions (including privacy policies, if and as 
            applicable) of another website or destination.
          </div>

          <div className='lead'>
            Qol Super App provides these Third-Party Account to do or undertake any of the following, as determined by 
            Qol Labs  in its sole discretion:
            
          </div>

        
          

          

          <div className='sub-content'>
            Qol Super App provides these Third-Party Websites and Third-Party Applications only as a convenience and 
            does not approve, monitor, endorse, warrant, or make any representations with respect to Third-Party 
            Websites or Third-Party Applications or their products or services. You use all links in Third-Party Websites 
            and Third-Party Applications at your own risk.
          </div>

          <div className='lead'>Limited License to You</div>
          <div className='sub-content'>
            These Terms grant users a limited and non-exclusive right to use the Services, including all Content 
            thereon. Except as indicated otherwise herein or in any additional terms or conditions, you may not 
            reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, 
            download, store, transmit, or otherwise exploit any of the Content on our Services, except as follows:
          </div>

          
           
            <div className='sub-content'>
              Your device may temporarily store copies of such materials in RAM incidental to your accessing and viewing 
              those materials.
            </div>
          
          <div >
            
            <div className='sub-content'>
              You may store files that are automatically cached by your web browser for display enhancement purposes,
              for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement 
              for such applications.
            </div>
          </div>

          <div className='sub-content'>
            If we provide any so-called "social media" features as part of the Services, you may take such actions 
            as are enabled by such features and are consistent with these Terms. You must not:
          </div>
          <div>
            
            <div className='sub-content'>
              Modify copies of any Content from the Services.
            </div>
          </div>
          <div>
           
            <div className='sub-content'>
              Use any illustrations, photographs, video, or audio sequences or any graphics available through the 
              Services separately from the accompanying terms.
            </div>
          </div>
          <div >
            
            <div className='sub-content'>
              Delete or alter any copyright, trademark, or other proprietary rights notices from copies of 
              materials available through the Services.
            </div>
          </div>

          <div className='sub-content'>
            If you wish to make any use of Content other than that set out in this Section, please address your request 
            to us via our official handles.
          </div>

        </div>

        <div>
          <div className='lead'>Risks Inherent to Blockchain Technologies</div>
          <div className='sub-content'>
            By accessing and using the Services, you represent that you understand the risks inherent to and associated 
            with blockchain networks and cryptographic systems and warrant that you have the technical sophistication 
            and working knowledge required to use, engage, or interact with blockchain networks, cryptographic systems, 
            smart contracts, and digital assets, such as Ether (ETH), Bitcoin (BTC), and Qol /PYM(collectively, 
            "Blockchain Technologies").
          </div>
          <div className='sub-content'>
            By accessing and using the Services, you assume all risks associated with using Blockchain Technologies, 
            including, but not limited to, the risk of hardware, software and internet connections failure or problems, 
            the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access 
            to information stored within your Wallet. You accept and acknowledge that Qol Labs  will not be responsible 
            for any communication failures, disruptions, errors, distortions or delays you may experience when using any 
            Blockchain Technologies for processing transactions, however caused.
          </div>
          <div className='sub-content'>
            Additionally, you understand that the markets for cryptographic and digital assets are nascent and highly 
            volatile due to risk factors including (but not limited to) adoption, speculation, technology, security, and 
            regulation. You acknowledge that we are not responsible for any of these variables or risks, do not own or 
            control any blockchain, and cannot be held liable for any resulting losses that you experience as a result 
            of your use of Blockchain Technologies. Accordingly, you understand and agree to assume full responsibility 
            for all of the risks of accessing and using Blockchain Technologies as part of your use of the Services.
          </div>
        </div>

        <div>
          <div className='lead'>Indemnification</div>
          <div className='sub-content'>
          <ul>  To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold harmless Qol 
            Labs or its sub products , and our respective past, present, and future employees, officers, directors, 
            contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, 
            subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (individually and 
            collectively, the "Qol Labs  Parties"), from and against all actual or alleged claims, damages, awards, 
            judgments, losses, liabilities of every kind, and nature whatsoever, whether known or unknown, that are 
            caused by a rise out of or are related to:
               <li>
              your use or misuse of the Services,
            </li>
            <li>
              your violation of these Terms, and/or
            </li>
            <li>
              your violation of any rights) of any third party. You agree to promptly notify Qol Labs  of any Claims) 
              and shall cooperate fully with the Qol Labs  Parties in defending such Claims.
            </li>
            <li>
              You further agree that the Qol Labs Parties shall have control of the defense or settlement of any third 
              party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN ANY 
              WRITTEN AGREEMENT(S) BETWEEN YOU AND QOL LABS.
            </li>
            </ul>
          </div>
          
          
        </div>

        <div>
          <div className='lead'>Limitation of Liability</div>
          <div className='sub-content'>
            EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY QOL LABS, THE SERVICES, CONTENT CONTAINED 
            THEREIN, AND ANY DIGITAL ASSET(S) LISTED THEREIN ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS 
            WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. QOL LABS  (AND ITS 
            AFFILIATE(S), LICENSOR(S) AND/OR OTHER BUSINESS ASSOCIATE(S) MAKES NO WARRANTY THAT THE SERVICES: (A) 
            WILL MEET YOUR REQUIREMENTS: (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE 
            BASIS; OR (C) WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL OR SAFE. QOL LABS REPRESENTED HERE AS QOL 
            SUPER APP DISCLAIMS ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT 
            LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
            TITLE AND NON-INFRINGEMENT AS TO THE SERVICES, CONTENT CONTAINED THEREIN. YOU ACCEPT THE INHERENT 
            SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US 
            RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
          </div>
          <div className='sub-content'>
            YOU AGREE AND ACKNOWLEDGE THAT QOL SUPER APP HAS NO CONTROL OVER AND MAKES NO GUARANTEES OR PROMISES 
            WITH RESPECT TO THE OWNERSHIP RECORD OR SMART CONTRACTS. QOL LABS MAKES NO OFFER OR INVITATION TO 
            ACQUIRE, PURCHASE, TRANSFER, SELL, OR OTHERWISE DEAL IN TOKENS. YOU WAIVE ANY AND ALL RIGHTS AND 
            CLAIMS YOU MAY HAVE, WHETHER KNOWN OR UNKNOWN AGAINST QOL LABS (AND ANY RELATED PARTIES) RELATED TO 
            THE EARNING FREQUENCY, AIRDROPS, TOKENS, AND REWARDS AVAILABLE ON THE SERVICES. TO THE FULLEST EXTENT 
            PROVIDED BY LAW, IN NO EVENT WILL QOL LABS  OR ITS AFFILIATES, OR ITS OR THEIR LICENSORS, SERVICE 
            PROVIDERS, EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS, OR DIRECTORS, BE LIABLE FOR ANY INDIRECT, 
            SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL 
            INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR 
            ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, LOSS OF DIGITAL ASSETS, LOSS OF 
            PRIVATE KEY, LOSS OF SEED PHRASE, LOSS OF ACCESS TO ANY DIGITAL WALLET, OR ANY OTHER DAMAGES OF ANY 
            KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE 
            SERVICES, INCLUDING BUT NOT LIMITED TO ANY CONTENT ON THE SERVICES OR ANY OTHER WEBSITES AND MOBILE 
            APPLICATIONS OR ANY ITEMS OBTAINED THROUGH THE SERVICES OR SUCH OTHER WEBSITES AND MOBILE 
            APPLICATIONS, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN 
            IF FORESEEABLE.
          </div>
          <div className='sub-content'>
            IN NO EVENT WILL QOL SUPER APP AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES, THE 
            ACCESS TO AND USE OF THE SERVICE, CONTENT, REWARDS OR ANY PRODUCTS OR SERVICES ON THE SERVICES EXCEED 
            THE GREATER OF $100 ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE 
            TERMS OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, 
            OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM SHALL BE DEEMED PERMANENTLY WAIVED AND BARRED. THIS SECTION 
            DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. BECAUSE SOME 
            STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR RANCEAlIENITIAI 
            AD INCIDENTAI ARACEC IN OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN 
            SUCH STATES OR JURISDICTIONS THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH STATES 
            OR JURISDICTIONS, OUR LIABILITY IS LIMITED TO THE EXTENT PERMITTED BY LAW, THEREBY MINIMIZING OUR 
            LIABILITY TO YOU TO THE LOWEST AMOUNT PERMITTED BY APPLICABLE LAW.
          </div>
        </div>

        <div>
          <div className='lead'>Dispute Resolution</div>
          <div className='sub-content'>
            This Section includes an arbitration agreement and an agreement that all claims will be brought only in an 
            individual capacity (and not as a class action or other representative proceeding). Please read it carefully.
          </div>

          <div className='lead'>Informal Process</div>
          <div className='sub-content'>
            You agree that in the event of any dispute between you and Qol Super App, you will first contact Qol labs 
            in writing and make a good faith sustained effort for no less than sixty(60) days to resolve the dispute 
            before resorting to more formal means of resolution, including without limitation, any arbitration.
          </div>

          <div className='lead'>Agreement to Arbitrate</div>
          <div className='sub-content'>
            Any dispute, controversy or claim that remains unresolved after the sixty (60) day informal dispute 
            resolution process concludes (collectively, "Dispute") relating in any way to these Terms or Qol Labs 
            services and/or products, including but not limited to the Services, or relating in any way to the 
            communications between you and Qol Labs or any other user of the Services, will be finally resolved 
            by binding arbitration.This mandatory arbitration agreement applies to you and to Qol Labs and or it’s 
            products. However, this arbitration agreement does not:
          </div>

          <div>
            
            <div className='sub-content'>
              govern any Dispute by Qol Super App for infringement of its intellectual property or access to the Services 
              that is unauthorized or exceeds authorization granted in these Terms, or
            </div>
          </div>
          <div>
           
            <div className='sub-content'>
              bar you from making use of applicable small claims court procedures in appropriate cases.
            </div>
          </div>
          <div className='sub-content'>
            You agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of this 
            provision, and that you and Qol Labs are each waiving the right to a trial by jury or to participate in a 
            class action. This arbitration provision is severable from and will survive any termination of these Terms.
          </div>

          <div className='lead'>Arbitration Procedure and Rules</div>
          <div className='sub-content'>
            Any Dispute, including all questions of arbitration, shall be settled by arbitration administered 
            by the International Centre for Dispute Resolution (ICDR) in accordance with its International 
            Arbitration Rules by a sole arbitrator. The parties hereto shall endeavor to agree upon the 
            arbitrator, and if they fail to do so within twenty-one (21) days of the commencement of the 
            arbitration, the appointment shall be made by the ICDR in accordance with the International 
            Arbitration Rules. The place, or legal seat of arbitration, shall be Geneva, Switzerland, and the 
            language of the arbitration shall be English.
          </div>
          <div className='sub-content'>
            You may only bring claims in your individual capacity on your own behalf, and not in any representative 
            capacity or on behalf of any class or purported class, and no arbitration you commence hereunder may be 
            joined with or include any claims by any other persons without the consent of both parties. Each party 
            shall be exclusively responsible for paying its own arbitration filing fees, which may later be allocated 
            by the arbitrator as set forth below.
          </div>
          <div className='sub-content'>
            Subject to the limitation of liability set forth above, the arbitrator shall have the power to grant any 
            interim or provisional measures that the arbitrator deems appropriate, including, but not limited to, 
            injunctive relief and specific performance, and any interim or provisional measures ordered by the 
            arbitrator may be specifically enforced by any court of competent jurisdiction as a final award. Nothing 
            herein, however, shall authorize the arbitrator to act as amiable compositeurs, to proceed ex aequo et 
            bono, or to exercise rights of iura novit curia. Each party hereto retains the right to seek interim 
            measures from a judicial authority, and any such request shall not be deemed incompatible with the 
            agreement to arbitrate or a waiver of the right to arbitrate. The arbitrator shall award the prevailing 
            party, if any as determined by the arbitrator, its reasonable costs, including reasonable attorney's fees. 
            Judgment on any award rendered by the arbitrator may be entered in any court of competent jurisdiction.
          </div>
          <div className='sub-content'>
            No information concerning an arbitration, beyond the names of the parties, their counsel or the relief 
            requested, may be unilaterally disclosed to a third party by any party unless required by law. Any 
            documentary or other evidence given by any party or witness in any arbitration shall be treated as 
            confidential by any party whose access to such evidence arises exclusively because of its 
            participation in the arbitration and shall not be disclosed to any third party (other than a witness 
            or expert), except as may be required by law. Any party who commences any judicial proceeding in 
            connection with an arbitration initiated hereunder shall endeavor to have the judicial record of any 
            such proceeding sealed to the extent permitted by law.
          </div>
          <div className='sub-content'>
            YOU AGREE THAT ANY CLAIM YOU MAY HAVE ARISING OUT OF OR RELATED TO YOUR RELATIONSHIP WITH QOL LABS MUST 
            BE BROUGHT WITHIN ONE (1) YEAR AFTER SUCH CLAIM AROSE; OTHERWISE, YOUR CLAIM WILL BE PERMANENTLY 
            BARRED.
          </div>

        </div>

        <div>
          <h4>Miscellaneous</h4>
          <div className='lead'>Governing Law and Venue</div>
          <div className='sub-content'>
            These Terms, your access to and use of the Services and Content, and your participation in the Services, 
            shall be governed by and construed and enforced in accordance with the laws of the United Arab Emirates and 
            its local provisions and regulations , without regard to conflict of law rules or principles of the State 
            of the Emirates(“The Emirates” ), or any other jurisdiction that would cause the application of the laws of 
            any other jurisdiction.
          </div>
          <div className='sub-content'>
            Any dispute between the parties that is not subject to arbitration or cannot be heard in small claims 
            court, shall be resolved in the state or federal courts sitting in the State of The Emirate . However, as a 
            consumer, you will benefit from any mandatory provisions of the law of the country in which you are 
            resident and nothing in these Terms affects your rights as a consumer to rely on such mandatory provisions 
            of local law.
          </div>

          <div className='lead'>Severability</div>
          <div className='sub-content'>
            If any term, clause or provision of these Terms is held invalid or unenforceable, then that term, clause, 
            or provision will be severable from these Terms and will not affect the validity or enforceability of any 
            remaining part of that term, clause, or provision, or any other term, clause, or provision of these Terms.
          </div>

          <div className='lead'>Wavier</div>
          <div className='sub-content'>
            If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce 
            our rights against you, or if we delay in doing so, that will not mean that we have waived our rights 
            against you and will not mean that you do not have to comply with those obligations. If we do waive a 
            default by you, we will only do so in writing, and that will not mean that we will automatically waive any 
            later default by you.
          </div>

          <div className='lead'>Assignability</div>
          <div className='sub-content'>
            These Terms are personal to you, and are not assignable, transferable, or sub-licensable by you except 
            with Qol Labs  prior written consent. Notices. All notices under these Terms will be in writing and 
            will be deemed to have been duly given when received, if personally delivered or sent by certified or 
            registered mail, return receipt requested; 
          </div>
          <div className='sub-content'>
            when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after it is 
            sent, if sent for next day delivery by recognized overnight delivery service.
          </div>
        </div>

        <div>
          <div className='lead'>Contact Us</div>
          <div className='sub-content'>
            If you have any questions or complaints about our Services or these Terms, please email us at 
            Support@qollabs.org or write to us at ( our official address). 
          </div>
        
            
          
        </div>

       
    
 
    
  
    </div>
    </div>
    </div>
  )
}

export default Terms