import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faMedium } from '@fortawesome/free-brands-svg-icons';
import footer from '../assets/footer_writeup.png';
import '../styles/footer.css'


function Footer() {

  return (
    <div className='footer p-10'>
      <div className='footer-below md:flex md:flex-row lg:flex lg:flex-row mx-auto justify-evenly'>
        
            
           <div className="write-up block text-center md:text-start lg:text-start mb-2">
            
            <div className='text-center md:text-start lg:text-start'>
            <div className='px-2  flex flex-row justify-center md:justify-start lg:justify-start'>
              <img src={footer} alt='logo' id="write-up" />
              </div>
              <p className='text-pretty px-2 py-1 text-white text-xs'>We are a trailblazing enterprise <br/>
                that aspires to 
                lead the <br/> world towards a decentralized future,<br/> 
                empowering individuals with the tools <br/>and applications 
                built on the <br/>cutting-edge PIM protocol.</p>
            </div>

            <div className='media-icons text-white text-center md:text-start lg:text-start'>
           <a className='p-2 text-white' aria-label='Link to Qol Labs X page' href='https://x.com/@qollabs_'><FontAwesomeIcon icon={faTwitter} /></a>
           <a className='p-2 text-white' aria-label='Link to Qol Labs Telegram handle' href='https://t.me/qollabs'><FontAwesomeIcon icon={faTelegram} /></a>
           <a className='p-2 text-white' aria-label='Link to Qol Labs Medium handle' href="https://medium.com/@qollabs.ai"><FontAwesomeIcon icon={faMedium} /></a>
          
           </div>

           </div>


           <div className='footer-copyright block text-center md:text-start lg:text-start mb-2'>
            <ul className='text-sm px-3 py-2'>
              <li className='py-2'>
                <a href="https://medium.com/@qollabs.ai" aria-label='Link to Qol Labs Medium page'>Blog</a>
              </li>
              <li className='py-2'>
                <a href="https://t.me/qollabs" aria-label='Link to Qol Labs Telegram handle'>Community</a>
              </li>
              <li className='py-2'>
                <a href="https://qollabs.org/contact" aria-label='Link to Qol Labs contact page'>Contact</a>
              </li>
              
            </ul>
            
           </div>
           
           

           
           <div className='media-icons text-white block px-3 text-sm text-center md:text-start lg:text-start mb-2'>
            <ul>
              <li className='py-2'>
              <a href='https://x.com/@qollabs_' aria-label='Link to Google Play Store with qol super app download link'>Qol Super App</a>
              </li>
           <li className='py-2'>
           <a href='https://qollabs.org' aria-label='Link to Qol Labs web page' disabled>Qol Academy <span className='text-stone-200'>(coming soon)</span></a>
           </li>
           <li className='py-2'>
           <a href="https://qollabs.org" aria-label='Link to Qol Labs web page' disabled>Qol DB <span className='text-stone-200'>(coming soon)</span></a>
           </li>
           <li className='py-2'>
           <a href="https://qollabs.org" aria-label='Link to Qol Labs web page' disabled>Qol Mart <span className='text-stone-200'>(coming soon)</span></a>
           </li>
           
            </ul>
           
          
           </div>
        
      </div> 

      <div className='footer-copyright text-xl text-white text-center'>
        <hr></hr>
            <p className='text-xs py-2 text-stone-400'>&copy;Copyright <span className='text-xs'>{(new Date().getFullYear())}</span> | All Rights Reserved</p>
           </div>
    </div>
  )
}

export default Footer